/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired



// init Masonry
var $grid = $('.grid').masonry({
  itemSelector: '.grid-item',
  columnWidth: '.grid-sizer',
  percentPosition: true
});

/*
    $('nav ul li a:not(:only-child)').click(function(e) {
      $(this).siblings('.nav-dropdown').toggle();
      // Close one dropdown when selecting another
      $('.nav-dropdown').not($(this).siblings()).hide();
      e.stopPropagation();
    });
    // Clicking away from dropdown will remove the dropdown class
    $('html').click(function() {
      $('.nav-dropdown').hide();
    });
    // Toggle open and close nav styles on click
    $('#nav-toggle').click(function() {
      $('nav ul').slideToggle();
    });
    // Hamburger to X toggle
    $('#nav-toggle').on('click', function() {
      this.classList.toggle('active');
    });
*/
if( $(".toggle .toggle-title").hasClass('active') ){
    $(".toggle .toggle-title.active").closest('.toggle').find('.toggle-inner').show();
  }
  $(".toggle .toggle-title").click(function(){
    if( $(this).hasClass('active') ){
      $(this).removeClass("active").closest('.toggle').find('.toggle-inner').slideUp(200);
    }
    else{ $(this).addClass("active").closest('.toggle').find('.toggle-inner').slideDown(200);
    }
  });


$.fn.slideFadeToggle  = function(speed, easing, callback) {
        return this.animate({opacity: 'toggle', height: 'toggle'}, 300);
}; 
    // Toggle open and close nav styles on click
    $('#nav-toggle').click(function(e) {
      e.preventDefault();
      $('nav.nav-primary').slideFadeToggle();
      $(this).find('.nav-toggle-bars').toggleClass('active');
    });

  function columnTitleHeight() {

    $('.kolommen').each(function(){

      $('.kolommen-kolom h3',this).height('auto');
  
      if ($(window).width() > 767) {

        var highestTitle = 0;

        $('.kolommen-kolom h3', this).each(function(){

            if($(this).height() > highestTitle) {
               highestTitle = $(this).height();
            }

        });  

        $('.kolommen-kolom h3',this).height(highestTitle);

      }

    });
   
  }

  // layout Masonry after each image loads
$grid.imagesLoaded().progress( function() {
  $grid.masonry('layout');
  columnTitleHeight();
});
  
    
  $(window).resize(function() {
    columnTitleHeight();
  });

$('.video-popup').magnificPopup({
  type: 'iframe',
  iframe: {


  patterns: {
    youtube: {
      index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

      id: 'v=', // String that splits URL in a two parts, second part should be %id%
      // Or null - full URL will be returned
      // Or a function that should return %id%, for example:
      // id: function(url) { return 'parsed id'; }

      src: 'https://www.youtube.com/embed/%id%?autoplay=1&rel=0&showinfo=0&theme=light&color=white&modestbranding=1&fs=1&controls=1' // URL that will be set as a source for iframe.
    }

    // you may add here more sources

  },

  srcAction: 'iframe_src', // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
}
});

$('.main .container .row ul li').wrapInner('<span class="li-content" />');

var $root = $('html, body');
$('a[href*=#]').not('.tabs a, .woocommerce-review-link').click(function() {
    var href = $.attr(this, 'href');
    $root.animate({
        scrollTop: $(href).offset().top
    }, 500, function () {
        window.location.hash = href;
    });
    return false;
});

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
